import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  CircularProgress,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  colors,
} from "@mui/material";
import {
  getJobs,
  deleteJob,
  getJobStatusCounts,
} from "../../../../../redux/Jobs/JobsSlice";
import {
  getAllJobData,
  getJobData,
  addJobCard,
  updateJobCard,
  deleteJobCard,
} from "../../../../../redux/dataSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import "./index.css";
import ServerSideDataGrid from "../../../../utilites/ServerSideDataGrid";

const JobsGrid = ({ status, startDate, endDate }) => {
  console.log("status fater ", status, startDate, endDate);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [jobId, setJobId] = useState(null);
  const jobs = useSelector((state) => state.Data.Resources.job);
  const userRole = sessionStorage.getItem("role")?.split(",") || [];
  console.log(jobs, "JOBS..");
  const [sortModel, setSortModel] = useState([
    { field: "createdAt", sort: "desc" },
  ]);
  const [searchText, setSearchText] = useState("");
  const [filterModel, setFilterModel] = useState({
    items: [{ id: "JobID", value: "", operatorValue: "contains" }],
  });
  const {
    data: regions,
    loading,
    error,
    selectedRegion,
  } = useSelector((state) => state.regions);
  console.log(selectedRegion?._id, "regions2");

  const notifySuccess = (message) =>
    toast.success(message, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });

  const notifyError = (errorMessage) =>
    toast.error(errorMessage, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  const filters = filterModel.items.reduce((acc, item) => {
    if (item.value) {
      const operator =
        item.operatorValue === "equals" ? "_equals" : "_contains";
      acc[`${item.columnField}${operator}`] = item.value;
    }
    return acc;
  }, {});

  useEffect(() => {
    if (!(jobs.data && jobs.data.length)) {
      if (selectedRegion && selectedRegion._id) {
        dispatch(
          getAllJobData({
            regionId: selectedRegion?._id,
            params: {
              JobStatus: status,
              startDate: startDate,
              endDate: endDate,
              page: 1,
              limit: 10,
              sortField: sortModel[0]?.field,
              sortOrder: sortModel[0]?.sort,
              Search: searchText,
              ...filters,
            },
          })
        );
      }
    }
    dispatch(getJobStatusCounts(selectedRegion?._id));
  }, [dispatch, selectedRegion]);

  const handleEdit = (id) => {

    navigate(`/editJob/${id}`, {
      state: { headerText: "Jobs" }, // State must be an object
    });
  };

  const handleDelete = async (jobId) => {
    const resultAction = await dispatch(deleteJob(jobId));
    if (deleteJob.fulfilled.match(resultAction)) {
      console.log("inside result action of delete", resultAction);
      notifySuccess("Job Deleted Successfully");
      dispatch(
        getAllJobData({
          regionId: selectedRegion?._id,
          params: {
            JobStatus: status,
            startDate: startDate,
            endDate: endDate,
            page: 1,
            limit: 10,
            sortField: sortModel[0]?.field,
            sortOrder: sortModel[0]?.sort,
            Search: searchText,
            ...filters,
          },
        })
      );
      dispatch(getJobStatusCounts(selectedRegion?._id));
    } else {
      notifyError("Failed to Delete job");
    }
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const columns = [
    {
      field: "JobID",
      headerName: "Job ID",
      width: 100,
      headerAlign: 'center',
      flex: 0.1,
      headerClassName: "header-center",
    },
    {
      field: "JobTitle",
      headerName: "Job Title",
      width: 150,
      headerAlign: 'center',
      flex: 0.1,
      headerClassName: "header-center",
    },
    {
      field: "No_Of_Positions",
      headerName: "No Of Positions",
      width: 130,
      align: "center",
      flex: 0.1,
      headerClassName: "header-center",
      headerAlign: 'center',
      headerClassName: "header-center"
    },
    {
      field: "ExperienceRequired",
      headerName: "Experience Range",
      headerClassName: "header-center",
      width: 160,
      headerAlign: 'center',
      align: "center",
      flex: 0.1,
      valueGetter: (params) => {
        const experience = params.row.ExperienceRequired;
        return `${experience.minExperience} - ${experience.maxExperience}`;
      },
    },
    {
      field: "JobAssignedTo",
      headerName: "Assigned To",
      headerClassName: "header-center",
      headerAlign: 'center',
      width: 180,
      flex: 0.1,
      renderCell: (params) => {
        const data = params.row.JobAssignedTo;
        return data ? `${data.email}` : "";
      },
    },
    {
      field: "JobPriority",
      headerName: "Priority",
      headerClassName: "header-center",
      headerAlign: 'center',
      flex: 0.1,
      width: 110,
      align: "center",
    },
    {
      field: "ExpectedClosingDate",
      headerName: "Expected Closing Date",
      headerClassName: "header-center",
      headerAlign: 'center',
      width: 180,
      flex: 0.1,
      type: "date",
      align: "center",
      valueFormatter: (params) =>
        params.value
          ? new Date(params.value).toLocaleDateString("en-US", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          })
          : // .replace(/\//g, "-")
          "",
    },
    {
      field: "JobLocation",
      headerName: "Location",
      headerClassName: "header-center",
      headerAlign: 'center',
      flex: 0.1,
      width: 100,
    },
    {
      field: "edit",
      headerName: "Actions",
      headerClassName: "header-center",
      headerAlign: 'center',
      width: 115,
      flex: 0.1,
      renderCell: (params) => (
        <div style={{ cursor: "pointer" }}>
          <Tooltip placement="top" title={"Edit"}>
            <IconButton
              style={{ color: "#24CCEF", cursor: "pointer" }} //  #32CD32 #FF1493
              onClick={() => handleEdit(params.row._id)}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          {userRole.includes("ADMIN") && (
            <Tooltip placement="top" title={"Delete"}>
              <IconButton
                style={{ color: "red", cursor: "pointer" }}
                onClick={() => {
                  setJobId(params.row._id);
                  setOpen(true);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      ),
    },
  ];
  return (
    <div style={{ width: "100%", backgroundColor: "white" }}>
      <div style={{ height: 600, width: "100%" }}>
        <ServerSideDataGrid
          loading={jobs.loading}
          columns={columns}
          fetchDataAction={getAllJobData}
          dataSelector="job"
          sliceKey="Data.Resources"
          status={status}
          startDate={startDate}
          endDate={endDate}
          handleAdd={() => {
            navigate("/addjob", {
              state: { headerText: 'Jobs' }  // State must be an object
            });
          }}
          addButtonName={"Create Job"}
          roles={["ADMIN", "HR"]}
        />
      </div>
      <ToastContainer />
      <Dialog
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            backgroundColor: "#f0f0f0",
            color: "black",
            boxShadow: "none",
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">{"Delete this Job?"}</DialogTitle>
        <DialogContent>
          <Typography color="textPrimary">
            Are you sure you want to delete this Job?
          </Typography>
        </DialogContent>
        <DialogActions>
          <button
            onClick={handleDialogClose}
            className="dialog-cancel-btn"
            color="primary"
          >
            Cancel
          </button>
          <button
            className="dialog-delete-btn"
            onClick={() => {
              handleDelete(jobId);
              handleDialogClose();
            }}
            color="primary"
            autoFocus
          >
            Delete
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default JobsGrid;
