import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { JobApi, apiURL, ConfigDataApi } from "../../utills/apiCalls";
import { toast } from "react-toastify";
import { getAllJobData } from "../dataSlice";
import { useDispatch } from "react-redux";

const token = sessionStorage.getItem("jwt_token");
const config = {
  headers: {
    Authorization: `Bearer ${token}`,
  },
};

const initialState = {
  job: {
    loading: false,
    page: 1,
    limit: 2,
    totalPages: 0,
    totalItems: 0,
    data: [],
    error: "",
  },
  jobsStatusCounts: [],
  configData: [],
  status: "idle",
  error: null,
};

export const createJob = createAsyncThunk(
  "job/createJob",
  async (jobData, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(JobApi, jobData, config);
      // dispatch(getAllJobData({ regionId: selectedRegion?._id, params: { page: 1, limit: 10 } }));
      return response.data;
    } catch (error) {
      //  catch (error) {
      //   return Promise.reject(error.response.data);
      if (error?.response?.data) toast.error(error?.response?.data);
      else toast.error("Something went wrong!");
      return rejectWithValue(error.response?.data);
      // }
    }
  }
);
// export const getJobs = createAsyncThunk(
//   "job/getJobs",
//   async (filters, { rejectWithValue }) => {
//     try {
//       const selectedRegion = filters.selectedRegion
//       const response = await axios.get(`${JobApi}region/${selectedRegion._id}`, {
//         params: filters,
//         ...config,
//       });
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response?.data);
//     }
//   }
// );

export const getJobs = createAsyncThunk(
  "job/getJobs",
  async ({ regionId, params }, { rejectWithValue }) => {
    try {
      console.log("params----->", params);
      console.log("region from project Dispatch", regionId)
      const queryParams = new URLSearchParams(params);
      console.log("q params ----->", queryParams);
      const response = await axios.get(
        `${JobApi}region/${regionId}?${queryParams.toString()}`,
        config
      );
      console.log(response, "project response");
      if (response?.data && response?.data?.projects) {
        response.data.projects = response.data.projects.map((p) => ({
          ...p,
          id: p?.projectId,
        }));
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getJobById = createAsyncThunk(
  "job/getJobById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(JobApi + id, config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const updateJob = createAsyncThunk(
  "job/updateJob",
  async ({ id, updatedJobData }, { rejectWithValue, dispatch }) => {
    try {
      console.log("job id from redux", id, updatedJobData);
      const response = await axios.put(
        `${JobApi}${id}`,
        updatedJobData,
        config
      );
      // dispatch(getAllJobData({ page: 1, limit: 10 }));

      return response.data;
    } catch (error) {
      if (error?.response?.data) toast.error(error?.response?.data);
      else toast.error("Something went wrong!");
      return rejectWithValue(error.response?.data);
    }
  }
);

export const deleteJob = createAsyncThunk(
  "job/deleteJob",
  async (id, { rejectWithValue }) => {
    try {
      console.log("job id from redux for delete", id);
      const response = await axios.delete(JobApi + id, config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getJobStatusCounts = createAsyncThunk(
  "job/statusCounts",
  async (regionId, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        apiURL + "jobConfigStatus/" + regionId,
        config
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getConfigData = createAsyncThunk(
  "job/configData",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(ConfigDataApi, config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const jobSlice = createSlice({
  name: "job",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // create job ==============================
      .addCase(createJob.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createJob.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.jobs = action.payload;
      })
      .addCase(createJob.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // get jobs ================================
      .addCase(getJobs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getJobs.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.jobs = action.payload;
      })
      .addCase(getJobs.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // get job by ID ===========================
      .addCase(getJobById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getJobById.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.jobs = action.payload;
      })
      .addCase(getJobById.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // update job ==============================
      .addCase(updateJob.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateJob.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(updateJob.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      //job status counts ==============================
      .addCase(getJobStatusCounts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getJobStatusCounts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.jobsStatusCounts = action.payload;
      })
      .addCase(getJobStatusCounts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // config data==================================
      .addCase(getConfigData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getConfigData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.configData = action.payload;
      })
      .addCase(getConfigData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default jobSlice.reducer;
